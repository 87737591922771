.layout {
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  background: var(--layers-background);
}

.hpWidth {
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 850px) {
  .hpWidth {
    /* max-width: 100%; */
    margin-left: 0;
    margin-right: 0;
  }
}

.hpWidthFeed {
  max-width: 1316px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4rem;
}

@media screen and (max-width: 850px) {
  .hpWidthFeed {
    max-width: 100%;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 0 1rem;
  }
}

.banner {
  margin-bottom: 4rem;
  max-width: 3840px;
  margin-left: auto;
  margin-right: auto;
}

.videoBanner {
  max-width: 3840px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.grid {
  margin: 2rem 0;
}

.padding {
  padding: 2rem 3.75rem;
}

.aisle {
  margin-top: 2rem;
  margin-bottom: 3rem;
}
